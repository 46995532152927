<template>
    <div class="container mx-auto">
        <form autocomplete="off">

            <div class="mt-6 md:mt-16 px-6 md:px-16" v-if="!isLoaded">
                <list-loader :width="400" :height="160" :speed="1" :animate="true" primaryColor="lightgray" secondaryColor="gray"></list-loader>
            </div>
            <router-view v-else :preset="preset" :toggleButtonSettings="toggleButtonSettings" :steps="steps" v-on:save="onSave" v-on:close="onClose"></router-view>
            <modal :showing="isSaving" transition="scale" :closeBtn="false" class="text-center">
                <div class="text-white text-4xl uppercase font-bold tracking-wider">Saving preset. Please wait...</div>
            </modal>
            <modal :showing="showConfirmClose" transition="scale" :closeBtn="false" class="text-center">
                <div class="text-white text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide">Close without saving?</div>
                <p class="text-white text-xl sm:text-2xl uppercase leading-tight font-bold tracking-wide">Any changes you've made will be lost,<br>are you sure you wish to exit without saving?</p>
                <br>
                <div class="flex -mx-2">
                    <a href="#" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/2 mx-2" @click.prevent="confirmClose">YES</a>
                    <a href="#" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/2 mx-2" @click.prevent="cancelClose">NO</a>
                </div>
            </modal>

        </form>
    </div>
</template>

<script>

    import { ListLoader } from "vue-content-loader";
    import Modal from '@/components/Modal.vue';

    export default {
        name: 'AddEditPreset',
        data: function() {
            return {
                isEditing: false,
                isLoaded: false,
                id: null,
                toggleButtonSettings: {
                    labels: true,
                    color: {checked: '#FFFFFF', unchecked: '#FFFFFF', disabled: '#CCCCCC'},
                    switchColor: {checked: '#0DE9FF', unchecked: '#E4E4E4'},
                    width: 60,
                    height: 30,
                    margin: 5
                },
                steps: [
                    {
                        name: 'photoDesignSettings',
                        title: 'Design your photo',
                        path: 'photo-design-settings',
                        completed: false
                    },
                    {
                        name: 'appDesignSettings',
                        title: 'Page Design',
                        path: 'app-design-settings',
                        completed: false
                    },
                    {
                        name: 'effectSettings',
                        title: 'Filter & Effects',
                        path: 'effect-settings',
                        completed: false
                    },
                    {
                        name: 'cameraSettings',
                        title: 'Camera Settings',
                        path: 'camera-settings',
                        completed: false
                    },
                    {
                        name: 'sendSettings',
                        title: 'Send Settings',
                        path: 'send-settings',
                        completed: false
                    },
                    {
                        name: 'printSettings',
                        title: 'Print Settings',
                        path: 'print-settings',
                        completed: false
                    },
                    {
                        name: 'dataCaptureSettings',
                        title: 'Data Capture',
                        path: 'data-capture-settings',
                        completed: false
                    },
                    {
                        name: 'saveSettings',
                        title: 'Save Settings',
                        path: 'save-settings',
                        completed: false
                    }
                ],
                preset: {
                    title: '',
                    photoDesignSettings: {
                        rawLayout: 0,
                        rawPhotoshootType: 0,
                        overlayImageS3Key: '',
                        removeOverlay: false
                    },
                    appDesignSettings: {
                        backgroundColorHexValue: '#000000',
                        textColorHexValue: '#FFFFFF',
                        captureOverlayS3Key: '',
                        removeCaptureOverlay: false,
                        boomerangEnabled: false,
                        gifEnabled: false,
                        photoEnabled: false,
                        videoEnabled: false,
                        touchlessStartEnabled: false,
                        shareType: 'email',
                        backgroundImageS3Key: '',
                        removeBackgroundImage: false,
                    },
                    cameraSettings: {
                        countDown: 3,
                        looped: 'false',
                        rawCameraDirection: 0,
                        rawCaptureQuality: 1,
                        recordingLength: 15,
                        reversed: 'false',
                        startDelay: 3
                    },
                    effectSettings: {
                        filtersEnabled: false,
                        framesEnabled: false,
                        captionsEnabled: false,
                        animatedImagesEnabled: false,
                        backgroundImagesEnabled: false,
                        portraitModeEnabled: false,
                        framesS3Keys: [],
                        gifS3Keys: [],
                        backgroundS3Keys: [],
                        removeFrameKeys: [],
                        removeGifKeys: [],
                        removeBackgroundKeys: [],
                        brightness: 0,
                        contrast: 1,
                        saturation: 1
                    },
                    sendSettings: {
                        emailEnabled: true,
                        messageBody: '',
                        messageTitle: 'Your Boothclub photo',
                        smsEnabled: false,
                        activityItemEnabled: false,
                        client: {
                            sendGridFromEmail: ''
                        }
                    },
                    printSettings: {
                        autoPrint: false,
                        printEnabled: false
                    },
                    dataCaptureSettings: {
                        enabled: true,
                        firstNameEnabled: false,
                        lastNameEnabled: false,
                        emailEnabled: true,
                        phoneEnabled: false,
                        postCodeEnabled: false,
                        dateOfBirthEnabled: false,
                        marketingOptinEnabled: false,
                        marketingOptinText: 'Opt in to receive marketing emails from Boothclub Ltd',
                        messageTitle: 'JOIN THE BOOTHCLUB COMMUNITY',
                        termsAndConditions: 'By participating, I understand and accept that my personal data will be sent to Megabooth LTD in the UK.',
                        titleEnabled: false,
                        logoImageS3Key: '',
                        removeLogo: false,
                        client: {
                            Name: '',
                            privacyPolicyURL: ''
                        }
                    },
                    saveSettings: {
                        saveToAWS: 'false',
                        saveToCameraRoll: 'false',
                        saveToSmugMugAlbum: 'false'
                    }
                },
                isSaving: false,
                showConfirmClose: false,
                runConfirmedClose: function() {}
            }
        },
        components: {
            ListLoader,
            Modal
        },
        mounted: function() {
            const $self = this;
            this.checkRoute(this.$route);
            if( typeof this.$route.params.id !== 'undefined' ) {
                this.id = this.$route.params.id;
                this.isEditing = true;
                this.getPreset(this.id);
            } else {
                //this.isLoaded = true;
                this.getClient();
            }
            this.$root.$on('savepreset', function() {
                $self.onSave('');
            });
        },
        destroyed: function() {
            this.$root.$off('savepreset');
        },
        methods: {
            getPreset: function(id) {
                const app = this;
                this.$http({
                    url: 'presets/'+id,
                    method: 'get'
                }).then(function(response) {
                    app.preset = response.data.preset;
                    app.preset.appDesignSettings.backgroundColorHexValue = '#'+app.preset.appDesignSettings.backgroundColorHexValue;
                    app.preset.appDesignSettings.textColorHexValue = '#'+app.preset.appDesignSettings.textColorHexValue;
                    app.steps.map(function(step) {
                       step.completed = true;
                    });
                    app.getClient();
                    //app.isLoaded = true;
                }).catch(function() {
                    app.$router.push({name: 'dashboard'});
                });
            },
            getClient: function() {
                const app = this;
                this.$http({
                    url: 'get-client',
                    method: 'get'
                }).then(function(response) {
                    app.preset.dataCaptureSettings.client = {
                        Name: response.data.client.Name,
                        privacyPolicyURL: response.data.client.privacyPolicyURL
                    };
                    app.preset.sendSettings.client = {
                        sendGridFromEmail: response.data.client.sendGridFromEmail
                    };
                    app.isLoaded = true;
                }).catch(function() {
                    app.$router.push({name: 'dashboard'});
                });
            },
            checkRoute: function(to) {
                if( to.name !== 'add-preset' && to.name !== 'edit-preset' ) {
                    document.body.classList.add('step-settings');
                } else {
                    document.body.classList.remove('step-settings');
                }
            },
            onSave: function(stepToComplete) {
                if( stepToComplete !== '' ) {
                    this.$router.push({path: this.$route.matched[0].path.replace(':id', this.$route.params.id)});
                    for(let stepkey in this.steps ) {
                        if( this.steps.hasOwnProperty(stepkey) && this.steps[stepkey].name === stepToComplete ) {
                            this.steps[stepkey].completed = true;
                            break;
                        }
                    }
                }
                const app = this;
                if( this.isSaving === false ) {
                    this.isSaving = true;
                    this.preset.appDesignSettings.backgroundColorHexValue = this.preset.appDesignSettings.backgroundColorHexValue.replace('#', '');
                    this.preset.appDesignSettings.textColorHexValue = this.preset.appDesignSettings.textColorHexValue.replace('#', '');
                    this.$http({
                        url: 'save-preset' + (this.id !== null ? '/' + this.id : ''),
                        method: this.id !== null ? 'put' : 'post',
                        data: {
                            preset: this.preset
                        }
                    })
                        .then(function (response) {
                            app.preset = response.data;
                            app.id = app.preset.configurationID;
                            app.preset.appDesignSettings.backgroundColorHexValue = '#'+app.preset.appDesignSettings.backgroundColorHexValue;
                            app.preset.appDesignSettings.textColorHexValue = '#'+app.preset.appDesignSettings.textColorHexValue;
                        })
                        .catch(function () {

                        }).finally(function () {
                            app.isSaving = false;
                            if( stepToComplete === '' ) {
                                app.$router.push({name: 'dashboard'});
                            }
                        });
                }
            },
            onClose: function(step, beforeEditing, filesSelected, toRoute) {
                if( filesSelected > 0 || ( JSON.stringify(this.preset[step]) !== JSON.stringify(beforeEditing) ) ) {
                    let app = this;
                    this.showConfirmClose = true;
                    this.runConfirmedClose = function() {
                        app.preset[step] = Object.assign({}, beforeEditing);
                        app.$router.push(toRoute);
                    }
                } else {
                    this.preset[step] = Object.assign({}, beforeEditing);
                    this.$router.push(toRoute);
                }
            },
            confirmClose: function() {
                this.showConfirmClose = false;
                this.runConfirmedClose();
            },
            cancelClose: function() {
                this.runConfirmedClose = function() {};
                this.showConfirmClose = false;
            }
        },
        watch: {
            $route: 'checkRoute',
            /*preset: {
                handler: function() {
                    //console.log(val);
                },
                deep: true
            }*/
        }
    }

</script>

<style>
    .step-settings #navigation {
        display: none;
    }
    body.step-settings {
        background: #FFFFFF;
    }
    .bg-turquoise {
        background: #0DE9FF;
    }
    label.vue-js-switch .v-switch-label.v-left, label.vue-js-switch .v-switch-label.v-right {
        left: -40px !important;
        color: #BFBFBF;
        top: 2px;
        @apply uppercase;
        @apply text-xl;
        @apply tracking-wider;
    }
    label.vue-js-switch .v-switch-label.v-left {
        color: #0DE9FF;
    }
    .v-switch-button {
        box-shadow: 0 1px 0 0 #C8C8C8;
    }
    .custom-min-height {
        min-height: calc(100vh - 48px);
    }
    @media (min-width: 768px) {
        .custom-min-height {
            min-height: calc(100vh - 128px);
        }
    }
    .custom-line-height-42px {
        line-height: 42px;
    }
</style>